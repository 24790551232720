import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { auth, googleProvider, appleProvider } from "../firebaseConfig";
import { signInWithPopup, OAuthProvider, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import appleLogo from "../assets/images/appleLogo.svg";
import googleLogo from "../assets/images/googleLogo.svg";
import profileImg from "../assets/images/profile.svg";
import proIcon from "../assets/images/proIcon.svg";
import cross from "../assets/images/crossLogin.svg";
import googleLogoWhite from "../assets/images/googleLogoWhite.svg";
import arrowRight from "../assets/images/arrowRight.svg";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 10px;
  height: 60px; /* Фиксированная высота */
  background-color: #000;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Title = styled.h2`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0 auto;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Контент ближе к верхнему краю */
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
  width: 100%;
  padding-top: 60px; /* Учитываем HeaderContainer */
  position: relative;
`;

const SignInBox = styled.div`
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* Минимальный отступ */
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const InfoText = styled.div`
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
`;

const Text = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 10px;
`;

const SignInButton = styled.button`
  background: rgba(34, 37, 46, 1);
  border: none;
  color: #fff;
  width: 342px;
  height: 48px;
  padding: 12px;
  margin: 10px 0;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 80px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 768px) {
    right: 20px;
    top: 14px;
  }
`;

const AccountBox = styled.div`
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px; /* Минимальный отступ */
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const ImageIcon = styled.img`
  // width: 64px;
  // height: 64px;
  // border-radius: 50%;
`;

const ArrowImg = styled.img`
  margin-left: auto;
`;

const ImgClose = styled.img`
  width: 48px;
  height: 48px;
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

const LogoutButton = styled(SignInButton)`
  background-color: #1c1f26;
  margin-top: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 0.7);
`;

const ProBox = styled.div`
  background-color: #1c1f26;
  padding: 12px;
  border-radius: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const ProText = styled.div`
  text-align: left;
  margin-left: 12px;
  color: white;
`;

const ProTitle = styled.p`
  margin: 0;
  padding-bottom: 5px;
  //styleName: 16 bold;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const ProSubtitle = styled.p`
  margin: 0;
  //styleName: 14 regular;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 0.5);
`;

const ProgressBox = styled.div`
  background-color: #1c1f26;
  padding: 20px;
  border-radius: 16px;
  margin-top: 20px;
  text-align: left;
  width: 90%;
  color: #fff;
`;

const firestore = getFirestore();

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);

  const handleSignIn = async (provider, providerName) => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("result", result);

      const userEmailKey = `${user.email}_${providerName}`;

      // Ссылка на коллекцию usersByEmail
      const usersByEmailRef = doc(firestore, "usersByEmail", userEmailKey);
      const userEmailSnapshot = await getDoc(usersByEmailRef);

      if (userEmailSnapshot.exists()) {
        const existingUserData = userEmailSnapshot.data();

        setProgress(existingUserData);
        localStorage.setItem("progress", JSON.stringify(existingUserData));
      } else {
        // Новый пользователь
        const userId = user.uid;
        const initialProgressData = {
          user: {
            id: userId,
            provider: providerName,
            email: user.email,
            pro: true,
          },
          data: {}, // Новый пользователь — нет прогресса
        };

        // Создаём запись в usersByEmail
        await setDoc(usersByEmailRef, { data: initialProgressData });

        // Сохраняем данные в localStorage
        setProgress({ data: initialProgressData });
        localStorage.setItem(
          "progress",
          JSON.stringify({ data: initialProgressData })
        );
      }
    } catch (error) {
      console.error("Ошибка авторизации:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    await signOut(auth);
    setProgress(null);
    localStorage.removeItem("progress");
  };

  useEffect(() => {
    const storedProgress = localStorage.getItem("progress");
    if (storedProgress) {
      setProgress(JSON.parse(storedProgress));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const storedProgress = localStorage.getItem("progress");

      if (storedProgress) {
        const parsedProgress = JSON.parse(storedProgress);

        try {
          // Получаем провайдера из локального хранилища
          const emailWithProvider = `${parsedProgress.data.user.email}_${parsedProgress.data.user.provider}`;

          // Ссылка на Firestore документ пользователя
          const userDocRef = doc(firestore, "usersByEmail", emailWithProvider);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setProgress(userData); // Обновляем состояние актуальными данными
            localStorage.setItem("progress", JSON.stringify(userData)); // Обновляем localStorage
          } else {
            handleSignOut();
          }
        } catch (error) {
          console.error("Ошибка загрузки данных из Firestore:", error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <SignInContainer>
      <HeaderContainer>
        <Title>{!progress ? "Войти в аккаунт" : "Настройки аккаунта"}</Title>
        <CloseButton onClick={() => navigate("/")}>
          <ImgClose src={cross} alt="Close" />
        </CloseButton>
      </HeaderContainer>

      {!progress ? (
        <SignInBox>
          <Avatar src={profileImg} alt="Avatar" />
          <Text>Авторизуйтесь</Text>

          <InfoText>
            Авторизуйтесь в своём аккаунте, чтобы <br /> управлять настройками
            или подпиской.
          </InfoText>

          <SignInButton onClick={() => handleSignIn(googleProvider, "google")}>
            <img src={googleLogo} alt="Google" width="24" />
            Войти через Google
          </SignInButton>

          <SignInButton onClick={() => handleSignIn(appleProvider, "apple")}>
            <img src={appleLogo} alt="Apple" width="24" />
            Войти через Apple
          </SignInButton>
        </SignInBox>
      ) : (
        <AccountBox>
          <Avatar
            src={
              (progress?.data?.user?.provider || progress?.user?.provider) ===
              "google"
                ? googleLogoWhite
                : appleLogo
            }
            alt="Avatar"
          />
          <Text>{progress?.data?.user?.email || progress?.user?.email}</Text>
          <InfoText>
            Вы авторизованы через{" "}
            {(progress?.data?.user?.provider || progress?.user?.provider) ===
            "google"
              ? "Google"
              : "Apple"}
          </InfoText>

          <ProBox onClick={() => navigate("/proinfo")}>
            <ImageIcon src={proIcon} alt="Pro Icon" />
            <ProText>
              <ProTitle>MiroLang Pro</ProTitle>
              <ProSubtitle>Попробуйте Pro версию</ProSubtitle>
            </ProText>
            <ArrowImg src={arrowRight} alt="Arrow"></ArrowImg>
          </ProBox>

          <LogoutButton onClick={handleSignOut}>Выйти из аккаунта</LogoutButton>
        </AccountBox>
      )}
      {loading && <p>Загрузка...</p>}
      {progress && (
        <ProgressBox>
          <h3>Ваш прогресс</h3>
          <p>
            <strong>Email:</strong>{" "}
            {progress?.data?.user?.email || progress?.user?.email}
          </p>
          <p>
            <strong>Провайдер:</strong>{" "}
            {progress?.data?.user?.provider || progress?.user?.provider}
          </p>
          <p>
            <strong>Pro-аккаунт:</strong>{" "}
            {progress?.data?.user?.pro || progress?.user?.pro ? "Да" : "Нет"}
          </p>
          <h4>Данные:</h4>
          <pre>{JSON.stringify(progress.data, null, 2)}</pre>
        </ProgressBox>
      )}
    </SignInContainer>
  );
};

export default SignIn;
