// import React, { useState } from "react";
// import styled from "styled-components";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import Header from "./components/Header";
// import Hero from "./components/Hero";
// import NextFeatures from "./components/NextFeatures";
// import ProgressTracking from "./components/ProgressTracking";
// import OfflineMode from "./components/OfflineMode";
// import Customization from "./components/Customization";
// import SpecialOffers from "./components/SpecialOffers";
// import Footer from "./components/Footer";
// import SupportModal from "./components/SupportModal";
// import SupportModalMobile from "./components/SupportModalMobile";
// import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
// import SignIn from "./components/SignIn";
// import ProInfo from "./components/ProInfo";

// const AppContainer = styled.div`
//   color: #ffffff;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 1);
//   background-image: linear-gradient(
//     to bottom,
//     rgba(30, 20, 23, 1),
//     rgba(0, 0, 0, 1)
//   );
//   background-repeat: no-repeat;
//   background-size: 100% 900px;
// `;

// const App = () => {
//   const [isWebModalOpen, setIsWebModalOpen] = useState(false);
//   const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

//   const openModal = () => {
//     if (window.innerWidth >= 768) {
//       setIsWebModalOpen(true);
//     } else {
//       setIsMobileModalOpen(true);
//     }
//   };

//   const closeModal = () => {
//     setIsWebModalOpen(false);
//     setIsMobileModalOpen(false);
//   };

//   return (
//     <Router>
//       <AppContainer>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <>
//                 <Header openModal={openModal} />
//                 <Hero />
//                 <NextFeatures />
//                 <ProgressTracking />
//                 <OfflineMode />
//                 <Customization />
//                 <SpecialOffers />
//                 <Footer openModal={openModal} />
//                 {isWebModalOpen && <SupportModal closeModal={closeModal} />}
//                 {isMobileModalOpen && (
//                   <SupportModalMobile closeModal={closeModal} />
//                 )}
//               </>
//             }
//           />
//           <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
//           <Route path="/signin" element={<SignIn />} />
//           <Route path="/proinfo" element={<ProInfo />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </AppContainer>
//     </Router>
//   );
// };

// export default App;

import React, { useState } from "react";
import styled from "styled-components";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import NextFeatures from "./components/NextFeatures";
import ProgressTracking from "./components/ProgressTracking";
import OfflineMode from "./components/OfflineMode";
import Customization from "./components/Customization";
import SpecialOffers from "./components/SpecialOffers";
import Footer from "./components/Footer";
import SupportModal from "./components/SupportModal";
import SupportModalMobile from "./components/SupportModalMobile";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import SignIn from "./components/SignIn";
import ProInfo from "./components/ProInfo";

const AppContainer = styled.div`
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  background-image: linear-gradient(
    to bottom,
    rgba(30, 20, 23, 1),
    rgba(0, 0, 0, 1)
  );
  background-repeat: no-repeat;
  background-size: 100% 900px;
`;

const App = () => {
  const [isWebModalOpen, setIsWebModalOpen] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const openModal = () => {
    if (window.innerWidth >= 768) {
      setIsWebModalOpen(true);
    } else {
      setIsMobileModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsWebModalOpen(false);
    setIsMobileModalOpen(false);
  };

  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header openModal={openModal} />
                <Hero />
                <NextFeatures />
                <ProgressTracking />
                <OfflineMode />
                <Customization />
                <SpecialOffers />
                <Footer openModal={openModal} />
                {isWebModalOpen && <SupportModal closeModal={closeModal} />}
                {isMobileModalOpen && (
                  <SupportModalMobile closeModal={closeModal} />
                )}
              </>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/proinfo" element={<ProInfo />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AppContainer>
    </Router>
  );
};

export default App;
